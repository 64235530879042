import React from "react";
import logo_white from "../assets/images/logo/logo-white.svg";
import facebook from "../assets/images/facebook.svg";
import twitter from "../assets/images/twitter.svg";
import linkedin from "../assets/images/linkedin.svg";
import whatsapp from "../assets/images/whatsapp.svg";
import skype from "../assets/images/skype.svg";
import { Link } from "react-scroll";

var year = new Date().getFullYear();

const Footer = () => {
  return (
    <>
      <footer className="footer-bg ">
        <div className="container-fluid">
          <div className="row text-light footer-content justify-content-center">
            <div className="col-lg-4 col-md-4 col-sm-12 ps-sm-3">
              <div>
                <img src={logo_white} className="img-fluid logo" alt="logo" />
              </div>
              <div className="contact pt-5">
                <div className="d-block pb-3">
                  <p className="text-light">E-mail</p>
                  <a
                    href="mailto:info@oshitechsolution.com"
                    className="text-white text-decoration-none"
                  >
                    info@oshitechsolution.com
                  </a>
                </div>
                <div className="py-3">
                  {/* <p className="text-light">Social media</p> */}
                  {/* <a
                    href="#"
                    className="text-decoration-none social-media-link"
                  >
                    <img src={facebook} alt="facebook" />
                  </a>
                  <a
                    href="#"
                    className="text-decoration-none social-media-link"
                  >
                    <img src={twitter} alt="twitter" />
                  </a>
                  <a
                    href="#"
                    className="text-decoration-none social-media-link"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </a> */}
                  {/* <a
                    href="skype:live:.cid.d8504577a469ca76?chat"
                    className="text-decoration-none social-media-link"
                  >
                    <img src={skype} alt="skype" />
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <hr className="hr-line" />
              <h5>Useful Links</h5>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className="nav-link text-white"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    duration={0}
                    offset={-80}
                    to="Home"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link text-white"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    duration={0}
                    offset={-80}
                    to="About"
                  >
                    About us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link text-white"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    duration={0}
                    offset={-80}
                    to="Service"
                  >
                    Services
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className="nav-link text-white"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    duration={0}
                    offset={-80}
                    to="Team"
                  >
                    Team
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <hr className="hr-line" />
              <h5>Our Services</h5>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link footer-link" to="">
                    Ui/Ux Design
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link footer-link" to="">
                    Website Development
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link footer-link" to="">
                    Application Development
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link footer-link" to="">
                    Software Development
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-10 mt-5 border-top border-1 text-center">
              <p className="py-4 text-light">
                &copy; {year} All Right's Reserved To &nbsp;
                <Link
                  className="nav-link text-light text-decoration-underline"
                  to="Home"
                >
                  Oshi Tech Solution
                </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>

      <a
        href="https://api.whatsapp.com/send?phone=+919340473241&lang=en&text=Hi Oshi Tech Solution"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsapp} className="fa fa-whatsapp whatsapp-icon" />
      </a>
    </>
  );
};
export default Footer;
