import React from "react";
import hero_img from "../assets/images/hero-img.png";
import square_374x392 from "../assets/images/square-374x392.png";
import text_link_analysis from "../assets/images/text-link-analysis.svg";
import design_ideas_regular from "../assets/images/design-ideas-regular.svg";
import test_desktop from "../assets/images/test-desktop.svg";
import rocket_launch_outline from "../assets/images/rocket-launch-outline.svg";
import customer_support from "../assets/images/customer-support.svg";
import ui_ux_design from "../assets/images/ui-ux-design.png";
import web_dev from "../assets/images/web-dev.png";
import app_dev from "../assets/images/app-dev.png";
import software_dev from "../assets/images/software-dev.png";
import react from "../assets/images/react.svg";
import nodejs from "../assets/images/node-js.svg";
import aws from "../assets/images/aws.svg";
import g_cloud from "../assets/images/g-cloud.svg";
import android from "../assets/images/android.svg";
import ios from "../assets/images/ios.svg";
import ui_ux from "../assets/images/ui-ux.svg";
import php from "../assets/images/php.svg";
import html5 from "../assets/images/html5.svg";
import css3 from "../assets/images/css3.svg";
import js from "../assets/images/js.svg";
import typescript from "../assets/images/typescript.svg";
import mysql from "../assets/images/mysql.svg";
import mariadb from "../assets/images/mariadb.svg";
import wordpress from "../assets/images/wordpress.svg";
import codeigniter from "../assets/images/codeigniter.svg";
// import img_22654_6_man from "../assets/images/22654-6-man.png";
// import img_avatar_vishal from "../assets/images/avatar/vishal-patidar-oshitechsolution.png";
// import img_avatar_adarsh from "../assets/images/avatar/adarsh-patidar-oshitechsolution.png";
// import img_avatar_nakul from "../assets/images/avatar/nakul-yadav-oshitechsolution.png";

import send_btn from "../assets/images/send-btn.svg";
import laptop_code from "../assets/images/laptop-code.svg";
import portfolio_paridhan_tailors from "../assets/images/portfolio/paridhan-tailors.png";
import portfolio_akshay_enterprises from "../assets/images/portfolio/akshay-enterprises.png";
import portfolio_food_delivery from "../assets/images/portfolio/food-delivery.png";

const Home = () => {
  const base_url = "https://oshitechsolution.com/";
  const moreProjects = [
    { name: "Car Insurance", link:"https://car-insurancepolicy4u.netlify.app/MOTOR" },
    { name: "Sheridan", link: base_url + "dev/nk/projects/sheridan" },
    // {
    //   name: "Itna World List",
    //   link: base_url + "dev/nk/projects/map/listing.html",
    // },
    {
      name: "Itna World Search",
      link: base_url + "dev/nk/projects/map/search.html",
    },
    { name: "Chat Boat", link: base_url + "dev/nk/projects/chat-bot" },
    // { name: "I Clean Dog", link: base_url + "dev/nk/projects/icleandog" },
    { name: "IPL", link: base_url + "dev/nk/projects/project-ipl" },
    { name: "Bryant", link: base_url + "dev/nk/projects/bryant" },
    // {
    //   name: "Email Template",
    //   link: base_url + "dev/nk/projects/email-template",
    // },
    // { name: "Sargent", link: base_url + "dev/ap/projects/sargent" },//DND
    {
      name: "Verify Dashboard 01",
      link: base_url + "dev/ap/projects/project-verifi",
    },
    // {
    //   name: "Verify Dashboard 02",
    //   link: base_url + "dev/ap/projects/project-verifi/index-two.html",
    // },
    // { name: "Travel", link: base_url + "dev/ap/projects/ticket-booking" },
    // {
    //   name: "Leali",
    //   link: base_url + "dev/ap/projects/project-leali/landing.html",
    // }, //DND
    // { name: "MK Juniors", link: base_url + "dev/my/projects/mk-Juniors" },
    // {
    //   name: "Wala Wala Dentistry",
    //   link: base_url + "dev/my/projects/wala-wala-dentistry",
    // }, //DND
    // {
    //   name: "Wala Wala Dentistry About",
    //   link: base_url + "dev/my/projects/wala-wala-dentistry/about-page.html",
    // }, //DND
    // {
    //   name: "Wala Wala Dentistry Service",
    //   link:
    //     base_url +
    //     "dev/my/projects/wala-wala-dentistry/walla-walla-dental-services.html",
    // }, //DND
    // { name: "Naseebdar", link: base_url + "dev/my/projects/naseebdar" },
  ];

  return (
    <>
      <section className="banner-section d-flex" id="Home">
        <div className="container align-self-center">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 col-sm-10 col-12 align-self-center  text-light banner-title">
              <h1>
                Welcome To <br />
                Oshi Tech Solution
              </h1>
              <p className="text-light">We are team of dedication</p>
              <div className="pt-4">
                <a
                  className="button primary-btn"
                  href="mailto:info@oshitechsolution.com"
                >
                  Contact Now
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-10 col-12 align-self-center pt-5 pt-lg-0 pt-md-0 banner-img">
              <img src={hero_img} className="img-fluid" alt="OTS" />
            </div>
          </div>
        </div>
      </section>
      <section className="section" id="About">
        <div className="container-fluid circle-img">
          <div className="row justify-content-center">
            <p className="fs-4 text-center section-tag">About Us</p>
            <div className="row d-lg-flex d-md-flex my-3 justify-content-center">
              <div className="col-lg-6 col-md-6 col-sm-6 align-self-center">
                <div className="heading">
                  <h2>How we work;</h2>
                  <p>check out the process</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <img
                  className="img-fluid w-75 d-flex mx-auto"
                  src={square_374x392}
                  alt="square-box"
                />
              </div>
            </div>
            <div className="row justify-content-evenly">
              <div className="col-lg-5 col-md-10 d-lg-flex d-md-flex d-sm-flex d-block mb-5 work-flow flex-row px-4">
                <div className="align-self-center p-3">
                  <img
                    src={text_link_analysis}
                    alt="Analysis"
                    className="work-flow-img"
                  />
                </div>
                <div className="p-3">
                  <h4 className="py-3">Research & analysis</h4>
                  <p>
                    There's a lot that we do before actually starting the
                    project. We do market research and analysis, understand the
                    competitive landscape, understand and document clients
                    design and branding preferences
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 d-lg-flex d-md-flex d-sm-flex d-block mb-5 work-flow px-4">
                <div className="align-self-center p-3">
                  <img
                    src={design_ideas_regular}
                    alt="Design"
                    className="work-flow-img"
                  />
                </div>
                <div className="p-3">
                  <h4 className="py-3">Design (if required)</h4>
                  <p>
                    Our next step is to design the UI of all the project screens
                    based on the approved wireframes and design guidelines. Our
                    focus on creating the design is to best represent your brand
                    and improve your digital presence.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 d-lg-flex d-md-flex d-sm-flex d-block mb-5 work-flow px-4">
                <div className="align-self-center p-3">
                  <img
                    src={laptop_code}
                    alt="Development"
                    className="work-flow-img"
                  />
                </div>
                <div className="p-3">
                  <h4 className="py-3">Development</h4>
                  <p>
                    At this step, we cater to the requirement for frontend,
                    backend, web services, and API development integration along
                    with preparing a strategy for speedy work methodology.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 d-lg-flex d-md-flex d-sm-flex d-block mb-5 work-flow px-4">
                <div className="align-self-center p-3">
                  <img
                    src={test_desktop}
                    alt="Testing"
                    className="work-flow-img"
                  />
                </div>
                <div className="p-3">
                  <h4 className="py-3">Testing</h4>
                  <p>
                    Our quality assurance testing phase involves Unit testing,
                    Manual testing, Regression testing, Security testing,
                    Automation testing, Load & Stress testing, performance, and
                    Code review. for best experience
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 d-lg-flex d-md-flex d-sm-flex d-block mb-5 work-flow px-4">
                <div className="align-self-center p-3">
                  <img
                    src={rocket_launch_outline}
                    alt="Launch"
                    className="work-flow-img"
                  />
                </div>
                <div className="p-3">
                  <h4 className="py-3">Launch</h4>
                  <p>
                    We tested all functionality, confirmed the appearance,
                    optimized your content, embedded your structured-data and
                    SEO optimizations, and have your domain name, hosting, and
                    SSL ready to go. It’s now the day of website launching, and
                    it's time to flip the switch to LIVE.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 d-lg-flex d-md-flex d-sm-flex d-block mb-5 work-flow px-4">
                <div className="align-self-center p-3">
                  <img
                    src={customer_support}
                    alt="Technical-support"
                    className="work-flow-img"
                  />
                </div>
                <div className="p-3">
                  <h4 className="py-3">Technical Support</h4>
                  <p>
                    We make sure that everything runs, every day, exactly as it
                    should. We conduct security audits, and monitor the server
                    load and traffic to optimize it. To make sure there is no
                    incompatibility, error, and bug in the system, we do full
                    testing of the system each month.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-light" id="Service">
        <div className="container-fluid circle-img">
          <div className="row align-item-center">
            <p className="fs-4 text-center section-tag">Service</p>
            <div className="col-lg-10 align-self-center mx-auto mb-5">
              <div className="heading text-center">
                <h2>Our Services</h2>
              </div>
            </div>
            <div className="row justify-content-center mx-auto my-4">
              <div className="col-lg-4 col-md-5 col-sm-10 align-self-center order-md-1 service-description">
                <h4 className="pb-2">UI/UX design</h4>
                <p>
                  We provide well planned ui/ux design with top className design
                  asthetics, Our creative web designers work closely with the
                  web development team to properly blend the design with user
                  experience and functionality.
                </p>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-10 order-md-2 py-3">
                <div className="service-img float-lg-end">
                  <img className="img-fluid" src={ui_ux_design} alt="ui/ux" />
                </div>
              </div>
            </div>
            <div className="row justify-content-center mx-auto my-4">
              <div className="col-lg-4 col-md-5 col-sm-10 align-self-center order-md-2 service-description">
                <h4 className="pb-2">Website development</h4>
                <p>
                  We have an excellent team of web developers who are capable of
                  creating creative and high-performance website development of
                  any complexity. we offer you a great website by combining the
                  new technology and the ui/ux design.
                </p>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-10 order-md-1 py-3">
                <div className="service-img ">
                  <img
                    className="img-fluid"
                    src={web_dev}
                    alt="web-development"
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-center mx-auto my-4">
              <div className="col-lg-4 col-md-5 col-sm-10 align-self-center service-description">
                <h4 className="pb-2">Application development</h4>
                <p>
                  Creating customized applications to meet unique business
                  requirements is an important asset to any organization that
                  wants to promote growth. Combining our developer’s skills &
                  experience with the latest technology we can deliver you with
                  robust applications according to your custom business
                  requirements.
                </p>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-10 ">
                <div className="service-img float-lg-end order-md-2 py-3">
                  <img
                    className="img-fluid"
                    src={app_dev}
                    alt="app-development"
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-center mx-auto my-4">
              <div className="col-lg-4 col-md-5 col-sm-10 align-self-center order-md-2 service-description">
                <h4 className="pb-2">Software development</h4>
                <p>
                  We provide custom software development solutions for all your
                  intra-business requirements and automation needs. If you have
                  a store or need a new one, our software development services
                  can surely help you.
                </p>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-10 order-md-1 py-3">
                <div className="service-img">
                  <img
                    className="img-fluid"
                    src={software_dev}
                    alt="software-development"
                  />
                </div>
              </div>
            </div>
            {/* <div className="row mt-5 px-0 py-0 mx-0 my-0 carousel-bg">
              <div className="col-10 col-lg-5 col-md-5 col-sm-8">
                <div className="py-5 ps-4  text-light">
                  <h3 className="fs-2 fw-bolder pb-2">Worked with</h3>
                  <p className="pt-2 text-light">
                    We use new techniques for the growth and progress of your
                    company so that you do not have any hindrance in doing your
                    work.
                  </p>
                  <p className="text-light">
                    We work on all types of technologies to provide the best
                    service. like programming language, framework, library, API
                    etc.
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-7 col-md-7 slide-bg">
                <div className="carousel-position">
                  <div
                    id="carouselExampleInterval"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner ">
                      <div
                        className="carousel-item active"
                        data-bs-interval="2000"
                      >
                        <div className="d-lg-flex d-md-flex d-sm-flex d-block">
                          <div className="text-center">
                            <div className="slide-img">
                              <img
                                src={react}
                                className="d-block "
                                alt="react"
                              />
                            </div>
                            <p>React</p>
                          </div>
                          <div className="text-center">
                            <div className="slide-img">
                              <img
                                src={nodejs}
                                className="d-block "
                                alt="node-js"
                              />
                            </div>
                            <p>Node js</p>
                          </div>
                          <div className="text-center">
                            <div className="slide-img">
                              <img src={aws} className="d-block " alt="aws" />
                            </div>
                            <p>AWS</p>
                          </div>
                          <div className="text-center">
                            <div className="slide-img">
                              <img
                                src={g_cloud}
                                className="d-block "
                                alt="g-cloud"
                              />
                            </div>
                            <p>Google Cloud</p>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item" data-bs-interval="2000">
                        <div className="d-lg-flex d-md-flex d-sm-flex d-sm-block">
                          <div className="text-center">
                            <div className="slide-img">
                              <img
                                src={android}
                                className="d-block "
                                alt="android"
                              />
                            </div>
                            <p>Android</p>
                          </div>
                          <div className="text-center">
                            <div className="slide-img">
                              <img src={ios} className="d-block " alt="ios" />
                            </div>
                            <p>ios</p>
                          </div>
                          <div className="text-center">
                            <div className="slide-img">
                              <img
                                src={ui_ux}
                                className="d-block "
                                alt="ui-ux"
                              />
                            </div>
                            <p>UI/UX</p>
                          </div>
                          <div className="text-center">
                            <div className="slide-img">
                              <img src={php} className="d-block " alt="php" />
                            </div>
                            <p>php</p>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="d-lg-flex d-md-flex d-sm-flex d-sm-block">
                          <div className="text-center">
                            <div className="slide-img">
                              <img
                                src={html5}
                                className="d-block "
                                alt="html5"
                              />
                            </div>
                            <p>Html5</p>
                          </div>
                          <div className="text-center">
                            <div className="slide-img">
                              <img src={css3} className="d-block " alt="css3" />
                            </div>
                            <p>CSS3</p>
                          </div>
                          <div className="text-center">
                            <div className="slide-img">
                              <img src={js} className="d-block " alt="js" />
                            </div>
                            <p>Javascript</p>
                          </div>
                          <div className="text-center">
                            <div className="slide-img">
                              <img
                                src={typescript}
                                className="d-block"
                                alt="typescript"
                              />
                            </div>
                            <p>Typescript</p>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="d-lg-flex d-md-flex d-sm-flex d-sm-block">
                          <div className="text-center">
                            <div className="slide-img">
                              <img
                                src={mysql}
                                className="d-block "
                                alt="mysql"
                              />
                            </div>
                            <p>My Sql</p>
                          </div>
                          <div className="text-center">
                            <div className="slide-img">
                              <img
                                src={mariadb}
                                className="d-block "
                                alt="mariadb"
                              />
                            </div>
                            <p>MariaDB</p>
                          </div>
                          <div className="text-center">
                            <div className="slide-img">
                              <img
                                src={wordpress}
                                className="d-block "
                                alt="wordpress"
                              />
                            </div>
                            <p>Wordpress</p>
                          </div>
                          <div className="text-center">
                            <div className="slide-img">
                              <img
                                src={codeigniter}
                                className="d-block "
                                alt="codeigniter"
                              />
                            </div>
                            <p>Codeigniter</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* <section className="section" id="Portfolio">
        <div className="container">
          <div className="row justify-content-center">
            <p className="fs-4 text-center mt-3 section-tag">Portfolio</p>
            <div className="align-self-center mx-auto mb-5">
              <div className="heading text-center">
                <h2>Our Template's Designs</h2>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 mt-3 cursor-pointer"
              onClick={() => {
                window.open(
                  "https://oshitechsolution.com/portfolio/paridhan-tailors/",
                  "_blank"
                );
              }}
            >
              <div className="card h-100 portfolio-content">
                <img
                  src={portfolio_paridhan_tailors}
                  className="img-fluid portfolio-img"
                  alt="Paridhan Tailors"
                />
                <div className="card-body">
                  <p className="card-title">Paridhan Tailors</p>
                  <p className="card-text mt-2">
                    We, Paridhan Tailors, situated at Bada Bazar, Dewas, Madhya
                    Pradesh, are a reputed name for providing perfect fit
                    tailoring services to our clients.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 mt-3 cursor-pointer"
              onClick={() => {
                window.open(
                  "https://oshitechsolution.com/portfolio/akshay-enterprises/",
                  "_blank"
                );
              }}
            >
              <div className="card h-100 portfolio-content">
                <img
                  src={portfolio_akshay_enterprises}
                  className="img-fluid portfolio-img"
                  alt="Akshay Enterprises"
                />
                <div className="card-body">
                  <p className="card-title">Akshay Enterprises</p>
                  <p className="card-text mt-2">
                    The main aim of improved packaging and labelling is to
                    ensure safe delivery of the product and effective promotion
                    of the brand.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 mt-3 cursor-pointer"
              onClick={() => {
                window.open(
                  "https://oshitechsolution.com/portfolio/food-delivery/",
                  "_blank"
                );
              }}
            >
              <div className="card h-100 portfolio-content">
                <img
                  src={portfolio_food_delivery}
                  className="img-fluid portfolio-img"
                  alt="Yummy - Food Delivery"
                />
                <div className="card-body">
                  <p className="card-title">Yummy - Food Delivery</p>
                  <p className="card-text mt-2">
                    Your food is just one click away, Order your food to get it
                    deliver at your door steps.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5 pb-5">
            <div className="align-self-center">
              <div className="heading text-center">
                <h5 className="card-title">More Works</h5>
              </div>
            </div>
            <div className="row">
              {moreProjects.map((value, index) => {
                return (
                  <div className="col-md-4 col-sm-6 col-12 pb-4 " key={index}>
                    <button
                      className="btn btn-btn-outline-primary w-100 custom_btn"
                      aria-current="page"
                      onClick={() => {
                        window.open(value.link, "_blank");
                      }}
                    >
                      {value.name}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="mt-5 team-bg" id="Team">
        <div className="container-fluid">
          <div className="row py-5 justify-content-center">
            <p className="fs-4 text-center text-white section-tag">Team</p>
            <div className="col-lg-10">
              <div className="heading text-center">
                <h2 className="text-white">Meet Our Team</h2>
              </div>
            </div>
            <div className="row pt-5 justify-content-center">
              <div className="col-lg-3 col-md-3 col-sm-5 col-10 mx-3 my-3 team-member-card">
                <div className="shadow rounded-3 mx-auto">
                  <img
                    src={img_avatar_vishal}
                    className="card-img"
                    alt="Vishal Patidar"
                    title="Vishal Patidar"
                  />
                </div>
                <div className="shadow rounded-3 text-center my-2 p-3 team-member-detail">
                  <h5 className="card-title">Vishal Patidar</h5>
                  <p className="card-text">Founder/Tech Head</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-5 col-10 team-member-card mx-3 my-3">
                <div className="shadow rounded-3 mx-auto">
                  <img
                    src={img_22654_6_man}
                    className="card-img"
                    alt="Pradhyumn Parihar"
                    title="Pradhyumn Parihar"
                  />
                </div>
                <div className="shadow rounded-3 text-center my-2 p-3 team-member-detail">
                  <h5 className="card-title">Pradhyumn Parihar</h5>
                  <p className="card-text">Business Consultant & ETL Analyst</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-5 col-10 team-member-card mx-3 my-3">
                <div className="shadow rounded-3 mx-auto">
                  <img
                    src={img_22654_6_man}
                    className="card-img"
                    alt="Abhishek Karodiya"
                    title="Abhishek Karodiya"
                  />
                </div>
                <div className="shadow rounded-3 text-center my-2 p-3 team-member-detail">
                  <h5 className="card-title">Abhishek Karodiya</h5>
                  <p className="card-text">Business Consultant</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-5 col-10 team-member-card mx-3 my-3">
                <div className="shadow rounded-3 mx-auto">
                  <img
                    src={img_avatar_nakul}
                    className="card-img"
                    alt="Nakul Yadav"
                    title="Nakul Yadav"
                  />
                </div>
                <div className="shadow rounded-3 text-center my-2 p-3 team-member-detail">
                  <h5 className="card-title">Nakul Yadav</h5>
                  <p className="card-text">Designer & Developer</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-5 col-10 team-member-card mx-3 my-3">
                <div className="shadow rounded-3 mx-auto">
                  <img
                    src={img_avatar_adarsh}
                    className="card-img"
                    alt="Adarsh Patidar"
                    title="Adarsh Patidar"
                  />
                </div>
                <div className="shadow rounded-3 text-center my-2 p-3 team-member-detail">
                  <h5 className="card-title">Adarsh Patidar</h5>
                  <p className="card-text">Designer & Developer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="section d-none" id="Contact-Us">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <p className="fs-4 text-center section-tag">Contact Us</p>
            <div className="row py-4 justify-content-center justify-content-evenly">
              <div className="col-lg-4">
                <div className="heading">
                  <h2>
                    Let's talk <br /> about everything
                  </h2>
                </div>
              </div>
              <div className="col-lg-4 align-self-center">
                <p>
                  Ready to team up or have a question to ask? See the links
                  below for contact info or reach out for more details!
                </p>
              </div>
            </div>
            <div className="form-bg mt-3 d-flex justify-content-center">
              <div className="col-lg-6 col-md-8 col-sm-10 my-5 px-3 py-4 text-center rounded form-box">
                <h5 className="fs-3">Let’s start your project</h5>
                <form className="needs-validation pt-3 justify-content-center">
                  <div className="row g-4 justify-content-center">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="fname"
                        className="form-control bg-transparent text-white"
                        placeholder="First name"
                        aria-label="First name"
                        id="exampleFormControlInput1"
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="lname"
                        className="form-control bg-transparent text-white"
                        placeholder="Last name"
                        aria-label="Last name"
                        id="exampleFormControlInput2"
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="email"
                        name="e-mail"
                        className="form-control bg-transparent text-white"
                        placeholder="E-mail"
                        id="inputEmail4"
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <textarea
                        className="form-control bg-transparent text-white"
                        placeholder="Leave a comment here"
                        id="floatingTextarea"
                        rows="5"
                      ></textarea>
                    </div>
                    <div className="col-md-12">
                      <button className="primary-btn submit-btn" type="submit">
                        <img
                          src={send_btn}
                          alt="send-icon"
                          className="img-fluid submit-btn-img"
                        />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Home;
