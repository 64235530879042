import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "../assets/css/style.css";
import { Route, Switch, useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import Home from "../component/Home.js";
import PageNotFound from "../component/ErrorHandling.js";
import Header from "./Header";
import Footer from "./Footer";

const App = () => {
  const history = useHistory();
  ReactGA.initialize("G-J4WQV62C7E");
  ReactGA.pageview(window.location.pathname + window.location.search);
  // useEffect(() => {
  //   return history.listen((location) => {
  //     ReactGA.pageview(window.location.pathname + window.location.search);
  //   });
  // }, [history]);
  return (
    <>

      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route component={PageNotFound} />
      </Switch>
      <Footer />
    </>
  );
};

export default App;
