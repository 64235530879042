import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import App from "./template/App";

//const ren = ReactDOMServer.renderToString(<BrowserRouter><App /></BrowserRouter>);
//ReactDOM.hydrate(ren, document.getElementById('root'));

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
