import React from "react";
import { NavLink } from "react-router-dom";
import image_404 from "../assets/images/20210425_175533.png";
const PageNotFound = () => {
  return (
    <>
      <section className="error-section">
        <div className="container-fluid">
          <div className="row mx-auto justify-content-center">
            <div className="col-lg-5 col-md-5 col-sm-10 col-12 align-self-center ">
              <h2 className="fs-1 fw-bold">Oops! something went wrong.</h2>
              <p className="mt-3">
                Sorry, but the page you are looking for does not exist, may have
                been removed, renamed, or is temporarily unavailable.
              </p>
              <NavLink className="button error-btn  mt-3" exact to="/">
                Back To Home
              </NavLink>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-10 col-12 align-self-center pt-5 pt-lg-0 pt-md-0 error_img">
              <img src={image_404} className="img-fluid" alt="error_img" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PageNotFound;
