import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/images/logo/logo-header.svg";
import { Link } from "react-scroll";

const Header = () => {
  useEffect(() => {
    var header = document.getElementById("header");
    var header_height = header.offsetHeight;
    document.getElementById("pd_div").style.paddingTop = header_height + "px";

    const toggle_btn = document.querySelector(".navbar-toggler");
    const nav_item = document.querySelectorAll(".nav-item");

    toggle_btn.addEventListener("click", () => {
      nav_item.forEach((link, index) => {
        if (link.style.animation) {
          link.style.animation = " ";
        } else {
          link.style.animation = `navlinkfade .5s ease forwards ${index / 10}s`;
        }
      });
      toggle_btn.classList.toggle("toggle_anime");
    });
  });
  return (
    <>
      <header id="header">
        <nav className="navbar navbar-expand-lg navbar-light bg-white">
          <div className="container-fluid">
            <div className="logo">
              <NavLink className="navbar-brand" exact to="/">
                <img src={logo} alt="logo" className="img-fluid logo" />
              </NavLink>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto my-2 mb-lg-2">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    duration={0}
                    offset={-80}
                    to="Home"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={-60}
                    duration={0}
                    to="About"
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={-60}
                    duration={0}
                    to="Service"
                  >
                    Service
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={-60}
                    duration={0}
                    to="Portfolio"
                  >
                    Portfolio
                  </Link>
                </li> */}
                {/* <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={-60}
                    duration={0}
                    to="Team"
                  >
                    Team
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div id="pd_div"></div>
    </>
  );
};
export default Header;
